@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;600;700&display=swap');
body{
    font-family: 'Catamaran' !important;
    text-align: left;
}

.aquash-header{
  background-image: linear-gradient(135.09deg, #4E5FFF 0%, #835AF6 100%);
  box-shadow: 0 6px 16px 4px rgba(0, 0, 0, 0.1);
}

.aquash-header .dropdown{
  cursor: pointer;
}

.nav-aquash-link{
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 16px;
  line-height: 26.24px;
  padding: 9px 20px !important;
  display: inline-block;
}

.nav-aquash-link:hover{
  color: #fff;
}

.aquash-header .collapse .navbar-nav .nav-item .login-link{
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  padding: 6px 20px;
  display: inline-block;
}

.aquash-header .collapse .navbar-nav .nav-item .dropdown-menu{
  right: 0px !important;
  left: auto;
  background-color: #F8FAFF;
  border-radius: 6px;
  border: none;
}

.aquash-header .collapse .navbar-nav .nav-item .dropdown-menu .dropdown-item{
  display: inline-block;
}

.aquash-bar-ico{
  color: #fff;
}

.aquash-bar-ico:focus, .aquash-bar-ico .fa-bars{
  outline: none !important;
}



/**** start main section****/

.aquash{
  font-family: 'Catamaran' !important;
  .fixed-top{
    z-index: 20 !important;
  }


  p{
    color: #212121;
    font-family: Catamaran;
    font-size: 18px;
    line-height: 24px;
  }
  .main{
    margin-top: 50px;
  }

figure img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

figure{
  margin: 0 0 1rem !important;
}

.section-bgs{
  padding: 60px 0px;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #F8FAFF 100%);
}

.main-img{
  margin-bottom: 30px;
}

.main-title{
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 36px;
  line-height: 58.32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.main-content{
  color: #FFFFFF;
  font-family: Rubik;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
}

.heding-title {
  color: #212121;
  font-family: Catamaran;
  font-size: 36px;
  line-height: 58.32px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 60px;
}

.bg-dark-pink{
  background-color: #BA89FF;
}

.bg-dark-blue{
  background-color: #8C8CFF;
}

.over {
  border-radius: 54.5px;
}
.form-select .form-check-label{
  margin-left: 10px;
}
.form-select .form-check-input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  background-clip: content-box;
  border: 2px solid #757575;
  background-clip: content-box;
  border-radius: 0px;
  margin-right: 10px;
}
.form-select .form-check-input[type="checkbox"]:checked {
  background-color: #0D6EFD;
  border-color: #0D6EFD;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-select .form-check-input[type="checkbox"]:hover {
  background-color: #8C8CFF;
  border: 2px solid #4E5FFF;
}
.form-select .dropdown-toggle::after{
  display: none;
}
.form-select .dropdown-item{
  white-space: inherit !important;
}
.form-select .form-check{
  display: flex;
  margin-bottom: 10px;
}
.form-select .dropdown-menu{
  padding-bottom:15px !important;
}

.actions-btn{
  display: none;
}

.form-select .btn{
  background-color: #F8FAFF !important;
  border: 1px solid #BDBDBD !important;
  border-radius: 10px !important;
  line-height: 20px;
  padding: .375rem .75rem !important;
  opacity: 0.5;
  padding-right: 27px !important;
}
.form-select .btn span, .form-select .form-check-label{
  color: #292A2C;
  font-family: Catamaran;
  font-size: 16px !important;
}
.form-select .btn:hover{
  border: 1px solid #757575 !important;
}
.form-select .btn:focus{
  border: 1px solid #236AEE;
  outline: none;
  box-shadow: none;
}

.it-accounts-for-over {
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 18px;
  line-height: 29.52px;
  padding: 10px 30px;
}

.business-img{
  padding-top: 100px;
}

.title-model{
  color: #4E5FFF;
  font-family: Catamaran;
  font-size: 30px;
  line-height: 49.2px;
  font-weight: 600;
  text-align: center;
  
}

.title-model span{
  color: #0e0b96;
}


.fual-caseback{
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 32px;
  line-height: 52.48px;
  background-color: #BA89FF;
  display: inline-block;
  margin: 0px auto;
  border-radius: 10px;
  padding: 10px 15px;
  font-weight: 600;
}

.title h2{
  color: #212121;
  font-family: Catamaran;
  font-size: 36px;
  line-height: 58.32px;
  text-align: center;
  font-weight: bold;
}

.title p{
  color: #757575;
  font-family: Catamaran;
  font-size: 30px;
  line-height: 49px;
  text-align: center;
  font-weight: 400;
}

.ueser-bg-title {
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0 6px 16px 4px rgba(0, 0, 0, 0.03);
  padding: 35px 15px;
  text-align: center;
  height: 650px;
  position: relative;
}

.my-circle {
  color: #212121;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  font-weight: 600;
}

.tagged-title {
  color: #212121;
  font-family: Catamaran;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.view-more-btn {
  color: #236AEE;
  font-family: Catamaran;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
  position: absolute;
  bottom: 20px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.choose-product-left{
  background-color: #f4f6f9;
  border-radius: 20px;
  padding: 58px 15px;
}

.choose-product-left h2{
  color: #757575;
  font-family: Catamaran;
  font-size: 36px;
  line-height: 59px;
  text-align: center;
  font-weight: 600;
}

.water-botel{
  margin-top: 50px;
  margin-bottom: 150px;
}

.benefits{
  color: #757575;
  font-family: Catamaran;
  font-size: 60px;
  line-height: 98px;
  text-align: center;
  font-weight: 600;
  margin: 184px 0px;
}

.choose-product-right{
  background-image: linear-gradient(135.09deg, #4E5FFF 0%, #835AF6 100%);
  border-radius: 20px;
  padding: 25px 15px;
}

.choose-product-right h2{
  color: #fff;
  font-family: Catamaran;
  font-size: 36px;
  line-height: 59px;
  text-align: center;
  font-weight: 600;
}

.water-botel-right{
  margin-top: 50px;
  margin-bottom: 20px;
}

.benefits-right p{
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  font-weight: 600;
}

.gg-coin{
  color: #4E5FFF;
  font-family: Catamaran;
  font-size: 30px;
  line-height: 49px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}


.gg-money{
  color: #212121;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36.08px;
  text-align: center;
}

.middle-text{
  padding: 100px 0px;
}

.franchise{
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  padding: 10px 15px;
  width: 450px;
  margin-bottom: 30px;
}

.manufacturers{
  color: #212121;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  margin-top: 100px;
}

.by-caseback{
  color: #4E5FFF;
  font-family: Catamaran;
  font-size: 36px;
  line-height: 59.04px;
  text-align: center;
  margin-top: 100px;
  font-weight: 600;
}

.gst{
  color: #4E5FFF;
  font-family: PingFangSC;
  font-size: 36px;
  line-height: 50.4px;
  text-align: center;
  font-weight:600;
}

.actual-franchise-fee{
  color: #757575;
  font-family: Catamaran;
  font-size: 30px;
  line-height: 49px;
  text-align: center;
  font-weight:600;
}

.franchise-owned-com {
  color: #212121;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
}

.upos-commission{
  color: #757575;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  font-weight: 600;
}

.upos-income{
  position: relative;
  list-style-type: none;
  display: block;
  margin-left: 30px;
  line-height: 49px;
  margin-bottom: 25px;
  font-size: 30px;
  color: #4E5FFF;
  font-weight: 600;
  font-family: 'Catamaran';
}
.upos-income::before{
  content: '-';
  position: absolute;
  left: -30px;
  background: #4E5FFF;
  height: 0px;
}

.all-financial-oper{
  color: #212121;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36.08px;
  text-align: left;
}

.missions-create{
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 36px;
  line-height: 59px;
  text-align: center;
  font-weight: 600;
}

.entrepreneurs{
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 26px;
  line-height: 42.64px;
  text-align: center;
  margin-bottom: 50px;
}

.enquire-now{
  background-color: #FFFFFF;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #4E5FFF;
  font-family: Catamaran;
  font-size: 26px;
  line-height: 42.64px;
  font-weight: bold;
  padding: 5px 30px;
  display: inline-block;
  margin: 15px 0px;
}

.enquire-now:focus{
  box-shadow: none;
}

.main-model{
  background-color: #fff;
  padding: 20px;
}

.model-background{
  background-color: #FFFFFF;
border-radius: 10px;
box-shadow: 0 6px 16px 4px rgba(173, 173, 173, 0.1);
padding: 20px 15px;
}

.modal-form{
  color: #212121;
font-family: Catamaran;
font-size: 10px;
line-height: 16px;
opacity: 0.5;
margin: 0px;
}

.modal-header{
  padding: 0px;
  border: none;
}

.modal-title{
  color: #212121;
font-family: Catamaran;
font-size: 22px;
line-height: 36px;
text-align: left;
padding: 0px;
font-weight: bold;
}

.modal-body{
  padding: 1rem 0rem;
}

.modal-footer{
  border: none;
}

.booking-label{
  color: #757575;
  font-family: Catamaran;
  font-size: 12px;
  line-height: 16px;
}

.booking-label span{
  color: #FE4F20;
}

.booking-form{
  background-color: #F8FAFF;
  border: 1px solid #BDBDBD !important;
  border-radius: 10px !important;
  color: #292A2C;
  font-family: Catamaran;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.5;
}

.booking-form::placeholder{
  color: #292A2C;
  font-family: Catamaran;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.5;
}

.booking-form:hover{
  border: 1px solid #757575;
}

.booking-form:focus{
  border: 1px solid #236AEE;
  outline: none;
  box-shadow: none;
}

.form-select{
  position: relative;
}

.form-select .fa-chevron-down{
  position: absolute;
  bottom: 12px;
  right: 12px;
  top: 13px;
  color: #292A2C !important;
  opacity: 0.3;
}

.text-chnage{
  opacity: 0.5;
}

.booking-policy{
  color: #212121;
  font-family: Catamaran;
  font-size: 14px;
  line-height: 18px;
}

.booking-policy input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  background-clip: content-box;
  border: 2px solid #757575;
  background-clip: content-box;
  border-radius: 0px;
  margin-right: 10px;
}

.booking-policy input[type="checkbox"]:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.booking-policy input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.booking-policy input[type="checkbox"]:hover {
  background-color: #8C8CFF;
  border: 2px solid #4E5FFF;
}

[type=radio]:focus, [type=checkbox]{
  display: block;
  position: relative;
  opacity: 1;
}

.cancel-btn{
  background-color: #BDBDBD;
  border-radius: 6px;
  border: 1px solid #BDBDBD;
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: bold;
}

.cancel-btn:not(:disabled):not(.disabled):active{
  background-color: #BDBDBD;
  border-color: #BDBDBD;
  box-shadow: 0 0 0 3px #BDBDBD;
  border: 1px solid #fff;
}

.cancel-btn:focus{
  box-shadow: 0 0 0 3px #BDBDBD;
  border: 1px solid #fff;
}

.submit-btn{
  background-color: #4E5FFF;
  border-radius: 6px;
  border: 1px solid #4E5FFF;
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: bold;
}

.submit-btn:not(:disabled):not(.disabled):active{
  background-color: #4E5FFF;
  border-color: #4E5FFF;
  box-shadow: 0 0 0 3px #4E5FFF;
  border: 1px solid #fff;
}

.submit-btn:focus{
  box-shadow: 0 0 0 3px #4E5FFF;
  border: 1px solid #fff;
}

.btn-group-vertical{
  display: none !important;
}


footer{
  background-color: #4B2DC2;
  padding: 60px 0px;
  background-image: none !important;
}

.contact-us {
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 30px;
  line-height: 49px;
  text-align: center;
  font-weight: 600;
}

.address {
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
}

.copyrights {
  color: #FFFFFF;
  font-family: Catamaran;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  background-color: #212121;
  padding: 25px 15px;
  margin: 0px;
}

@media (min-width: 769px){
  .col-sm-pull-6 {
      right: 50%;
  }

  .col-sm-push-6 {
      left: 50%;
  }
}

@media (max-width: 768px){
  .business-img{
      padding-top: 0px;
      padding-bottom: 50px;
  }

  .by-caseback-model{
      padding-top: 50px;
  }

  .main-content{
      font-size: 18px;
  }

  .state-heading{
      margin: 0px auto;
      width: 100%;
      text-align: center;
  }

  .fual-caseback{
      font-size: 22px;
      line-height: 35.48px;
  }

  .benefits-right p{
      font-size: 18px;
      line-height: 30px;
  }

  .choose-product-right h2, .choose-product-left h2{
      font-size: 30px;
  }

  .benefits{
      font-size: 42px;
      margin: 223px 0px;
  }

  .middle-text{
      padding: 0px 0px;
  }

  .state-heading{
      margin-top: 3rem;
  }

  .by-caseback{
      font-size: 30px;
      line-height: 40px;
  }

  .actual-franchise-fee{
      margin-bottom: 30px;
      font-size: 18px;
  }

  .gst{
      font-size: 25px;
  }

  .upos-income, .all-financial-oper{
      font-size: 18px;
      line-height: 30px;
  }

  .entrepreneurs{
      font-size: 25px;
  }

  .nav-grange-link{
      font-size: 13px;
  }


  .ueser-bg-title{
    height: auto;
  }
}


@media (max-width: 468px){

  .main{
      margin-top: 50px;
  }

  .main-title{
      font-size: 30px;
      line-height: 40px;
  }

  .main-content{
      font-size: 16px;
      line-height: 30px;
  }

  .heding-title{
      font-size: 25px;
      line-height: 35px;
  }

  .it-accounts-for-over{
      font-size: 14px;
  }

  .title-model{
      font-size: 17px;
      line-height: 36.2px;
      margin-top: 15px;
  }

  .fual-caseback{
      font-size: 14px;
      line-height: 24px;
  }

  .franchise{
      width: 100%;
  }

  .business-img{
      padding-bottom: 0px;
      padding-top: 50px;
  }

  .title h2{
      font-size: 25px;
  }

  .title p{
      font-size: 18px;
  }

  .choose-product-left{
      margin-bottom: 20px;
  }

  .choose-product-left h2{
      font-size: 25px;    
  }

  .water-botel{
      margin: 0px auto;
      margin-top: 20px;
  }

  .benefits{
      font-size: 30px;
      margin: 30px 0px;
  }

  .choose-product-right h2{
      font-size: 25px;
  }

  .benefits-right p{
      font-size: 18px;
      line-height: 30px
  }

  .middle-text{
      padding: 20px 15px;
  }

  .gg-money{
      font-size: 18px;
      line-height: 30px;
  }

  .state-heading{
      margin-top: 35px;
  }

  .by-caseback{
      font-size: 21px;
      font-size: 21px;
      line-height: 40px;
      margin-top: 50px;
  }

  .gst{
      font-size: 24px;
  }

  .actual-franchise-fee{
      font-size: 19px;
      line-height: 38px;
  }

  .upos-income{
      font-size: 18px;
      line-height: 30px;
  }

  .missions-create{
      font-size: 35px;
  }

  .entrepreneurs{
      font-size: 20px;
  }

  .address{
      font-size: 17px;
      line-height: 30px;
  }
}   

@media (min-width: 1430px){
  .container {
    max-width: 1440px;
  }
}

}
.aquash-loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #8C8CFF;
  border-bottom: 8px solid #8C8CFF;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}