/* Top Bar Start*/
.business .container .top-bar {
    padding: 45px 0 50px 0;
    margin-top: 20px;
}
.nav_logo img {
    width: 190px;
    height: auto;
    position: absolute;
    right: 0%;
    top: 23px;
}
/* Top Bar End*/

/* Slick Slider Start */
.slick-list {
    border-radius: 20px;
}
.Slick_slider img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 20px;
}
.react-multi-carousel-dot--active button {
    width: 25px !important;
    height: 12px !important;
    border-radius: 10px !important;
    background-color: #236aee !important;
    border: 2px solid #236aee !important;
}
.react-multi-carousel-dot button {
    background-color: grey;
}
.CarouselSlider {
    margin-bottom: 50px;
}
.CarouselSlider img {
    margin-bottom: 20px;
}
/* Slick Slider End */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
small,
b,
button {
    font-family: "Poppins", sans-serif;
}

.mb_50 {
    margin-bottom: 50px;
}

.pt_100 {
    padding-top: 100px;
}

.ptb-60 {
    padding: 60px 0;
}

.display_flex {
    display: flex;
    flex-wrap: wrap;
}

.custom_btn {
    background-color: #116efd !important;
    color: #fff !important;
    border: 1px solid #fff !important;
    font-size: 16px !important;
    width: 150px !important;
    height: 40px !important;
    transition: 0.5s !important;
}

.custom_btn:hover,
.custom_btn:active,
.custom_btn:focus {
    background-color: #1568e4 !important;
    color: #fff !important;
    border: 1px solid #fff !important;
    font-size: 16px !important;
    width: 150px !important;
    height: 40px !important;
}

.mt_170 {
    margin-top: 170px !important;
}

/* Banner Start */
.ban_pos {
    .banner_content img {
        width: 100%;
        height: auto;
    }
    .banner_content h3 {
        color: #236aee;
        font-size: 30px;
        font-weight: 400;
    }

    .banner_content p {
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
        color: #757575;
        text-align: justify;
    }
    .banner_content p b {
        font-weight: 400;
        font-size: 18px;
        color: #333;
    }
    .gsdc-content p{
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
        color: #757575;
        text-align: justify;
    }
}
/* Banner Start */

/* Vision Mission Start */
.custome-bg {
    background-color: #236aee;
}
.custome-bg2 {
    background-color: #cbcbcb;
}
/* Vision Mission End */
.MV {
    .MV_box {
        width: 100%;
        padding: 20px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 15px -6px #000;
        margin: 0 auto 20px auto;
    }
    .MV_box {
        .MV_box_img .vision {
            display: block;
            width: 30%;
            height: auto;
            margin: 0 auto;
        }
        .MV_box_img .mission {
            display: block;
            width: 30%;
            height: auto;
            margin: 0 auto;
        }
    }
    .MV_box .MV_box_content {
        h3 {
            font-size: 30px;
            font-weight: 600;
            color: #236aee;
            text-align: center;
            margin: 20px 0;
        }

        p {
            color: #757575;
            font-weight: 300;
            font-size: 18px;
            text-align: justify;
            padding: 0;
            margin-bottom: 10px;
        }
        p:nth-child(3) {
            margin-bottom: 0px;
        }
    }
}
/* Center Content Start */
.content {
    text-align: center;
    margin: 60px 0 20px 0;
}

.content h3 {
    font-size: 30px;
    color: #236aee;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 37px;
}

.content p {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    color: #757575;
}
/* Center Content End */

/* Our Team Start */
.SUXEX_team {
    .our_team_header {
        background-color: #236aee;
        border-radius: 50px;
        border: 1px solid #236aee;
        position: absolute;
        top: -131px;
        width: 100%;
        z-index: 2;
    }
    .our_team_header h4 {
        color: #fff;
        font-size: 32px;
        margin: 0;
        padding: 10px 0;
    }
    .Team_bg_box {
        background-color: #fff;
        border-radius: 20px;
        border: 3px solid #236aee;
        position: relative;
        top: -100px;
    }
    .our_team_card {
        padding: 50px 10px 20px 20px;
        position: relative;
    }
    .our_team_card::before {
        content: "";
        position: absolute;
        top: 35%;
        right: -23px;
        width: 2px;
        height: 45%;
        margin: auto 0;
        display: block;
        z-index: 1;
        background: #92aee1;
    }
    .our_team_card3::before {
        display: none;
    }
    .our_team_card h4 {
        text-align: center;
        font-size: 22px;
        color: #236aee;
    }
    .our_team_card p {
        text-align: justify;
        font-size: 17px;
        line-height: 24px;
        color: #757575;
        font-weight: 300;
    }
}
/* Our Team End */

/* Responsive CSS Start */
@media screen and (max-width: 770px) and (min-width: 250px) {
    .business .container .top-bar {
        padding: 20px 00px 20px 00px !important;
    }
    .nav_logo img {
        width: 95px;
        position: absolute;
        right: 2%;
        top: 17px;
    }
    .login-btn {
        display: flex;
        margin: 0 auto;
    }
    .ban_pos {
        position: relative;
        top: 50px;
        margin-top: -50px;
    }
    .CarouselSlider img {
        margin-bottom: 20px;
        padding: 0 10px;
    }
    .banner_content img {
        width: 90% !important;
        height: auto;
        display: flex !important;
        margin: 0 auto 30px auto !important;
    }
    .banner_content h3 {
        font-size: 20px !important;
    }
    .banner_content p {
        font-size: 16px !important;
    }
    .meet {
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
        color: #757575;
        text-align: justify;
        color: red;
    }
    .MV {
        .MV_box {
            .MV_box_content h3 {
                font-size: 25px;
                font-weight: 500;
                color: #084ac4;
                text-align: center;
                margin: 10px 0 10px 0;
            }
            .MV_box_content p {
                color: #757575;
                font-weight: 300;
                font-size: 16px;
                text-align: justify;
                padding: 0;
                margin-bottom: 10px;
            }
            .MV_box_img .vision {
                display: block;
                width: 30%;
                height: auto;
                margin: 0 auto;
            }
            .MV_box_img .mission {
                display: block;
                width: 30%;
                height: auto;
                margin: 0 auto;
            }
        }
    }
    .content {
        margin: 30px 0 20px 0 !important;
    }
    .content h3 {
        font-size: 20px !important;
        margin-bottom: 20px !important;
        padding: 0 5px;
    }
    .content p {
        font-size: 18px !important;
        margin: 10px 0 !important;
    }
    .pt_100 {
        padding-top: 10px;
    }
    .SUXEX_team {
        .our_team_header {
            left: 0%;
            width: 100%;
        }
        .our_team_header h4 {
            font-size: 20px;
            margin: 0px;
            padding: 10px 0;
        }
        .Team_bg_box {
            position: relative;
            top: -100px;
            margin: 0;
        }
        .our_team_card {
            padding: 30px 10px 20px 20px;
        }
        .our_team_card p {
            font-size: 15px;
        }
        .our_team_card::before {
            top: 100%;
            right: 22%;
            width: 50%;
            height: 2px;
        }
    }
}

@media screen and (max-width: 1080px) and (min-width: 670px) {
    .MV {
        .MV_box {
            .MV_box_img .vision {
                width: 100px;
            }
        }
    }
    .p-0 {
        padding: 0 15px !important;
    }
}
/* Responsive CSS End */
