@import url('https://fonts.googleapis.com/css2?family=Catamaran !important:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,700;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('//db.onlinewebfonts.com/c/05e476e067ffef74ca5686f229c40a63?family=PingFang+SC');

body{
      font-family: 'Catamaran !important';
      text-align: justify;
  }
 

.grange-header{
    background-image: linear-gradient(135.09deg, #F15922 0%, #E51C3C 100%);
    box-shadow: 0 6px 16px 4px rgba(0, 0, 0, 0.1);
    padding: 8px;
    z-index: 2 !important          ;

}
.navbar-brand{
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.nav-grange-link{
    color: #FFFFFF;
    font-family: Catamaran !important;
    font-size: 16px;
    line-height: 26.24px;
    padding: 9px 20px !important;
    display: inline-block;
}

.nav-grange-link:hover{
    color: #fff;
}

.grange-header .collapse .navbar-nav .nav-item .login-link{
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    padding: 6px 20px;
    display: inline-block;
}

.grange-header .collapse .navbar-nav .nav-item .dropdown-menu{
    right: 0px !important;
    left: auto;
    background-color: #F8FAFF;
    border-radius: 6px;
    border: none;
}

.grange-header .collapse .navbar-nav .nav-item .dropdown-menu .dropdown-item{
    display: inline-block;
}

.grange-bar-ico{
    color: #fff;
}

.grange-bar-ico:focus, .grange-bar-ico .fa-bars{
    outline: none !important;
}



/**** start main section****/

.grange{
.main{
    margin-top: 66px !important;
}
p{
    font-family: Catamaran !important;
}    

figure img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.section-bg{
    padding: 60px 0px;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFCFB 100%);
}

.bg-orange{
    background-color: #F15922;
}

.bg-red{
    background-color: #E51C3C;
}

.store-title{
    font-family: Catamaran !important;
    font-size: 36px;
    line-height: 59.04px;
    font-weight: bold;
    margin-bottom: 35px;
}

.store-subtitle{
    font-family: Rubik;
  font-size: 24px;
  line-height: 40px;
  padding: 0px 15px;
}

.heding-title{
    color: #E51C3C !important;
    font-family: Catamaran !important;
    font-size: 36px;
    line-height: 58.32px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 60px;
}

.grange-title{
    color: #212121;
    font-family: Catamaran !important;
    font-size: 30px;
    line-height: 49.2px;
    padding: 100px 0px;
}

.rate-title{
    color: #212121;
    font-family: Catamaran !important;
    font-size: 30px;
    line-height: 49.2px;
}

.fact-menu{
    padding-top: 65px;
}

.carts-img{
    padding: 100px 0px;
}

.padding-section{
    padding-top: 3rem;
}

.it-accounts-for-over{
    color: #FFFFFF;
    font-family: Catamaran !important;
    font-size: 18px;
    line-height: 29.52px;
    border-radius: 54.5px;
    padding: 10px 30px;
}

.over{
    border-radius: 54.5px;
}

.the-local-kirana-is {
    color: #212121;
    font-family: Catamaran !important;
    font-size: 26px;
    line-height: 42.64px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 25px;
}

.store-menu{
    position: relative;
    list-style-type: none;
    display: block;
    margin-left: 30px;
    font-size: 22px;
    line-height: 36px;
    margin-bottom: 25px;
}

.store-menu:before{
    content: '';
    position: absolute;
    background-image: linear-gradient(135.09deg, #F15922 0%, #E51C3C 100%);
    border-radius: 100%;
    width: 18px;
    height: 18px;
    top: 10px;
    left: -30px;
}

.upos-cell{
    color: #212121;
    font-family: Catamaran !important;
    font-size: 30px;
    line-height: 49px;
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
}

.stock-title h2{
    color: #E62745;
    font-family: Catamaran !important;
    font-size: 36px;
    line-height: 58.32px;
    text-align: center;
    font-weight: 700;
}

.stock-title h3{
    color: #212121;
    font-size: 36px;
    line-height: 50.4px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
}

.stock-title h4{
  color: #757575;
  font-family: Catamaran !important;
  font-size: 30px;
  line-height: 49px;
  text-align: center;
  font-weight: 600;
}

.stock-title p{
    color: #757575;
    font-family: Catamaran !important;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
}

.case-back{
    color: #212121;
    font-family: Catamaran !important;
    font-size: 26px;
    line-height: 42.64px;
    text-align: center;
    font-weight: bold;
}

.franchise-will-be-op {
    color: #757575;
    font-family: Catamaran !important;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    font-weight: bold;
}

.franchise-owned-com {
    color: #757575;
    font-family: Catamaran !important;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
}

.ueser-bg-title{
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 6px 16px 4px rgba(0, 0, 0, 0.03);
    padding: 35px 15px;
    text-align: center;
    height: 650px;
    position: relative;

}

.my-circle {
    color: #212121;
    font-family: Catamaran !important;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    font-weight: 600;
  }

.tagged-title{
    color: #212121;
    font-family: Catamaran !important;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
}


.view-more-btn{
    color: #236AEE;
    font-family: Catamaran;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    font-weight: 600;
    position: absolute;
    bottom: 20px;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.also-give-e-commerc {
    color: #212121;
    font-family: Catamaran !important;
    font-size: 30px;
    line-height: 49px;
    text-align: center;
    font-weight: 600;
}


.note-get-instant-g{
    color: #757575;
    font-family: Catamaran !important;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
}

.gg-coins-on-every-pu {
    color: #212121;
    font-family: Catamaran !important;
    font-size: 36px;
    line-height: 59px;
    text-align: center;
    font-weight: 600;
}

.you-get-100-gg-coin {
    color: #212121;
    font-family: Catamaran;
    font-size: 22px;
    line-height: 30.8px;
}

.best-items-at-best-p {
    color: #F15922;
    font-family: Catamaran !important;
    font-size: 30px;
    line-height: 49px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 35px;
}

.enquire-now{
    background-color: #FFFFFF;
    border: 1px solid #fff;
    border-radius: 8px;
    color: #F15922;
    font-family: Catamaran;
    font-size: 26px;
    line-height: 42.64px;
    font-weight: bold;
    padding: 5px 30px;
    display: inline-block;
    margin: 15px 0px;
}

.enquire-now:focus{
    box-shadow: none;
}


/**********Model******/
.main-model{
    background-color: #fff;
    padding: 20px;
}

.model-background{
    background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 6px 16px 4px rgba(173, 173, 173, 0.1);
  padding: 20px 15px;
}

.modal-form{
    color: #212121;
  font-family: Catamaran;
  font-size: 10px;
  line-height: 16px;
  opacity: 0.5;
  margin: 0px;
}

.modal-header{
    padding: 0px;
    border: none;
}

.modal-title{
    color: #212121;
  font-family: Catamaran;
  font-size: 22px;
  line-height: 36px;
  text-align: left;
  padding: 0px;
  font-weight: bold;
}

.modal-body{
    padding: 1rem 0rem;
}

.modal-footer{
    border: none;
}

.booking-label{
    color: #757575;
    font-family: Catamaran;
    font-size: 12px;
    line-height: 16px;
}

.booking-label span{
    color: #FE4F20;
}

.booking-form{
    background-color: #F8FAFF;
    border: 1px solid #BDBDBD !important;
    border-radius: 10px !important;
    color: #757575;
    font-family: Catamaran;
    font-size: 16px !important;
    line-height: 20px;
}

.booking-form::placeholder, .form-select>div>select{
    color: #212121;
    font-family: Catamaran;
    font-size: 16px !important;
    line-height: 20px;
    opacity: 0.5;
    padding-right: 30px;
}

.booking-form:hover{
    border: 1px solid #757575 !important;
}

.booking-form:focus{
    border: 1px solid #236AEE;
    outline: none;
    box-shadow: none;
}

.form-select{
    position: relative;
}

.form-select .fa-chevron-down{
    position: absolute;
    bottom: 12px;
    right: 12px;
    top:10px;
    color: #BDBDBD;
}

.form-select .btn-block{
    display: none;
}

.bs-container.large-bootstrap-select {
    .dropdown-menu {
      width: 500px;
      li a span.text {
        word-wrap: break-word;
        white-space: normal;
      }
    }
  }
.booking-policy{
    color: #212121;
    font-family: Catamaran;
    font-size: 14px;
    line-height: 18px;
}

.booking-policy input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-clip: content-box;
    border: 2px solid #757575;
    background-clip: content-box;
    border-radius: 0px;
    margin-right: 10px;
}

.booking-policy input[type="checkbox"]:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.booking-policy input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.booking-policy input[type="checkbox"]:hover {
    background-color: #8C8CFF;
    border: 2px solid #4E5FFF;
}

/***********/

.form-select .form-check-label{
    margin-left: 10px;
}

.form-select .form-check-input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-clip: content-box;
    border: 2px solid #757575;
    background-clip: content-box;
    border-radius: 0px;
    margin-right: 10px;
}

.form-select .form-check-input[type="checkbox"]:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-select .form-check-input[type="checkbox"]:hover {
    background-color: #8C8CFF;
    border: 2px solid #4E5FFF;
}

.form-select .dropdown-toggle::after{
    display: none;
}

.form-select .dropdown-item{
    white-space: inherit !important;
}

.form-select .form-check{
    display: flex;    
    margin-bottom: 10px;
}

.form-select .dropdown-menu{
    padding-bottom:15px !important;
}

.form-select .btn{
    background-color: #F8FAFF !important;
    border: 1px solid #BDBDBD !important;
    border-radius: 10px !important;
    line-height: 20px;
    padding: .375rem .75rem !important;
    opacity: 0.5;
    padding-right: 30px;
}

.form-select .btn span, .form-select .form-check-label{
    color: #292A2C;
    font-family: Catamaran;
    font-size: 16px !important;
}

.form-select .btn:hover{
    border: 1px solid #757575 !important;
}

.form-select .btn:focus{
    border: 1px solid #236AEE;
    outline: none;
    box-shadow: none;
}

/**********/

[type=radio]:focus, [type=checkbox]{
    display: block;
    position: relative;
    opacity: 1;
}

.cancel-btn{
    background-color: #BDBDBD;
    border-radius: 6px;
    border: 1px solid #BDBDBD;
    color: #FFFFFF;
    font-family: Catamaran;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 25px;
    text-transform: uppercase;
    font-weight: bold;
}

.cancel-btn:not(:disabled):not(.disabled):active{
    background-color: #BDBDBD;
    border-color: #BDBDBD;
    box-shadow: 0 0 0 3px #BDBDBD;
    border: 1px solid #fff;
}

.cancel-btn:focus{
    box-shadow: 0 0 0 3px #BDBDBD;
    border: 1px solid #fff;
}

.submit-btn{
    background-color: #F15922;
    border-radius: 6px;
    border: 1px solid #F15922;
    color: #FFFFFF;
    font-family: Catamaran;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 25px;
    text-transform: uppercase;
    font-weight: bold;
}

.submit-btn:not(:disabled):not(.disabled):active{
    background-color: #F15922;
    border-color: #F15922;
    box-shadow: 0 0 0 3px #F15922;
    border: 1px solid #fff;
}

.submit-btn:focus{
    box-shadow: 0 0 0 3px #F15922;
    border: 1px solid #fff;
}

/**************End MOdal*********/


.footers{
    background-image: linear-gradient(135.09deg, #F15922 0%, #E51C3C 100%)  !important;
    padding: 60px 0px  !important;
}

.mission-to-create {
    color: #FFFFFF;
    font-family: Catamaran !important;
    font-size: 36px;
    line-height: 59px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
}

.entrepreneurs{
    color: #FFFFFF;
  font-family: Catamaran !important;
  font-size: 26px;
  line-height: 42.64px;
  text-align: center;
  font-weight: 600;
}

.hr-border{
    border-top: 1px solid #fff;
    margin: 30px 0px;
    width: 100%;
}

.contact-us {
    color: #FFFFFF;
    font-family: Catamaran !important;
    font-size: 30px;
    line-height: 49px;
    text-align: center;
    font-weight: 600;
}

.address{
    color: #FFFFFF;
    font-family: Catamaran !important;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
}
.copyrights{
    color: #FFFFFF;
    font-family: Catamaran !important;
    font-size: 14px ;
    line-height: 18px;
    text-align: center;
    background-color: #212121;
    padding: 25px 15px;
    margin: 0px;
}

@media(max-width: 768px){

    .banner-space{
        padding: 30px 15px;
    }
    
    .section-bg{
        padding: 30px 0px;
    }

    .grange-title{
        padding: 10px 0px;
        font-size: 25px;
    }

    .heding-title{
        /* font-size: 30px; */
        line-height: 45px;
    }

    .rate-title{
        font-size: 25px;
        line-height: 40px;
        margin-top: 30px;
    }

    .carts-img{
        padding: 0px;
        padding-top: 50px;
    }

    .the-local-kirana-is{
        font-size: 20px;
    }

    .upos-cell, .stock-title h4{
        font-size: 20px;
    }

    .stock-title h3, .case-back{
        font-size: 25px;
    }

    .stock-title p{
        font-size: 18px;
    }

    .footers-logo{
        margin-bottom: 30px;
    }
}


@media(max-width: 468px){
    .grange .store-menu{
        font-size: 16px;
        line-height: 30px;
    }
  
    .section-bg{
        padding: 30px 0px;
    }

    .store-title{
        font-size: 25px;
        line-height: 34px;
    }

    .store-subtitle{
        font-size: 16px;
        line-height: 28px;
    }

    .grange-title{
        font-size: 16px;
        line-height: 30px;
        padding: 0px 0px;
    }

    .heding-title, .stock-title h2, .stock-title h3, .stock-title h4, .case-back, .also-give-e-commerc{
        font-size: 20px;
        line-height: 36px;
    }

    .rate-title{
        font-size: 18px;
        line-height: 31px;
    }

    .fact-menu, .padding-section{
        padding-top: 15px;
    }

    .it-accounts-for-over{
        font-size: 10px !important;
        line-height: 16px !important;
    }

    .carts-img{
        padding: 30px 0px;
    }

    .the-local-kirana-is{
        font-size: 18px;
        line-height: 30px;
    }

    .upos-cell, .note-get-instant-g{
        font-size: 18px;
        line-height: 30px;
    }

    .stock-title p, .franchise-owned-com, .you-get-100-gg-coin{
        font-size: 16px;
    }

    .franchise-will-be-op{
        font-size: 18px;
    }

    .gg-coins-on-every-pu, .best-items-at-best-p{
        font-size: 25px;
    }

    .footers-logo{
        width: 50%;
    }

    .mission-to-create, .contact-us{
        font-size: 25px;
        margin: 0px;
    }

    .entrepreneurs, .address{
        font-size: 18px;
        line-height: 30px;
    }
}


figure{
    margin: 0 0 1rem !important;
}

.logo-text{
    color: #fff;
}

.store-menu b{
    color: #F36426;
}

.cart-img{
    padding: 100px 0px;
}

.stockist{
    color: #212121;
    font-family: Catamaran !important;
    font-size: 36px;
    line-height: 59.04px;
    font-weight: bold;
    margin-bottom: 20px;
}

.stockist span{
    color: #F36426;
}

.maintained{
    color: #757575;
    font-family: Catamaran !important;
    font-size: 22px;
    line-height: 36px;
}

.stock-hardware-se {
    color: #757575;
    font-family: Catamaran;
    font-size: 30px;
    line-height: 49px;
    font-weight: bold;
}

.important-facts {
    color: #212121;
    font-family: Catamaran;
    font-size: 30px;
    line-height: 49.2px;
    font-weight: bold;
    text-align: justify;
    margin-bottom: 20px;

}

@media (max-width:768px){
    .top-space{
        padding: 0px;
    }

    .grange-logo {
        margin: 20px auto;
    }

    .stockist{
        font-size: 20px;
        line-height: 40px;
    }

    .stock-hardware-se{
        font-size: 20px;
        line-height: 27px;    
    }

    .maintained{
        font-size: 16px;
        line-height: 20px;
    }

    .store-menu{
        font-size: 18px;
        line-height: 25px;
    }

    .store-menu:before{
        top: 5px;
    }

    .cart-img {
        padding: 30px 0px;
    }
}

@media (min-device-width : 768px)   and (max-device-width : 1440px)  { 
    img.grange-logo {
        width: 40%;
    }

    .store-title{
        font-size: 30px !important;
        margin-bottom: 10px;
    }

    .store-subtitle{
        font-size: 17px !important;
        line-height: 35px;
    }
}

@media (max-width: 468px){
    .grange{
        margin-top: 71px !important;
    }
}

@media (min-width: 1430px){
    .container {
          max-width: 1440px;
    }
}

}

.fade{
    opacity: 1;
}

.grange-loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #F15922;
    border-bottom: 8px solid #F15922;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }